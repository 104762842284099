<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4" class="pb-0">
        <h2 class="main-color">{{ $t("Monthly Salaries") }}</h2>
      </v-col>
      <v-col cols="12" sm="8" class="pb-0 mt-2">
        <v-row>
          <v-col cols="6" sm="3" class="py-0">
            <div class="d-flex">
              <!-- <label for="from" class="px-2 pt-2 grey--text">Year</label> -->
              <v-select
                :label="$t('Year')"
                v-model="filter.selectedYear"
                item-text="year"
                item-value="year"
                :items="years"
                @change="hideLabel = true"
                solo
                dense
              ></v-select>
            </div>
          </v-col>
          <v-col cols="6" sm="3" class="py-0">
            <div class="d-flex">
              <!-- <label for="to" class="px-2 pt-2 grey--text">Month</label> -->
              <v-select
                :label="$t('Month')"
                v-model="filter.selectedMonth"
                :items="months"
                item-text="month"
                item-value="id"
                @change="hideLabel = true"
                dense
                solo
              ></v-select>
            </div>
          </v-col>
          <v-col cols="6" sm="6" class="px-2 py-1 text-right">
            <v-btn
              class="modal-btn-save"
              @click="calculateSalary"
              :loading="salaryLoading"
              >{{ $t("Calculate Salary") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0 text-center">
        <v-icon
          large
          class="main-color"
          @click="printDialog = true"
          :title="$t('Print')"
          >print</v-icon
        >
        <v-menu
          class=""
          offset-y
          :close-on-content-click="false"
          :nudge-width="300"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
              <i
                v-on="on"
                class="fas fa-filter main-color fa-lg"
                :title="$t('Filter')"
              ></i>
            </v-btn>
          </template>
          <v-list class="select px-4">
            <v-autocomplete
              :placeholder="$t('Job Title')"
              :items="jobs"
              @change="getAllData"
              item-text="name"
              item-value="id"
              v-model="filter.jobs"
              multiple
              solo
            ></v-autocomplete>
          </v-list>
        </v-menu>
        <label class="search" for="inpt_search">
          <input
            :title="$t('Search')"
            id="inpt_search"
            type="text"
            @focus="inputFocus"
            @blur="inputBlur"
            v-model="search"
          />
        </label>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          :items-per-page="15"
          class="elevation-1 level1"
          hide-default-footer
        >
          <template v-slot:item.allowances="{ item }">
            <v-tooltip bottom v-if="item.allowances_list.length > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="#FFB400" dark v-bind="attrs" v-on="on">
                  label
                </v-icon>
              </template>
              <p
                v-for="(index, allow) in item.allowances_list"
                :key="index"
                style="color: white"
              >
                {{ item.allowances_list[allow]["name"] }} :
                {{ item.allowances_list[allow]["value"] }}
                {{ item.allowances_list[allow]["type"] }}
                <br />
              </p>
              <v-divider color="white"></v-divider>
              {{ $t("Total") }} : {{ item.allowances }}
            </v-tooltip>
            <span>{{ item.allowances }}</span>
          </template>
          <template v-slot:item.deduction="{ item }">
            <v-tooltip bottom v-if="item.deduction_list.length > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="#FFB400" dark v-bind="attrs" v-on="on">
                  label
                </v-icon>
              </template>
              <p
                v-for="(index, allow) in item.deduction_list"
                :key="index"
                style="color: white"
              >
                {{ item.deduction_list[allow]["name"] }} :
                {{ item.deduction_list[allow]["value"] }}
                {{ item.deduction_list[allow]["type"] }}
                <br />
              </p>
              <v-divider color="white"></v-divider>
              {{ $t("Total") }} : {{ item.deduction }}
            </v-tooltip>
            <span>{{ item.deduction }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="item.id"
              @click="PrintPayslip(item.id)"
              :title="$t('Print')"
              >print</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>

    <!-- ========================================printDialog ======================================  -->
    <v-dialog
      v-model="printDialog"
      persistent
      max-width="400"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{ $t("Print") }}</v-card-title>

        <v-card-text>
          <v-autocomplete
            :placeholder="$t('Job Title')"
            :items="jobs"
            item-text="name"
            item-value="id"
            v-model="filter.job"
            solo
          ></v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="printDialog = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("Close") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="PrintSalaries"
            class="modal-btn-save yesBtn"
            >{{ $t("Print") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EmployeesSalaries",
  data() {
    return {
      printDialog: false,
      snack: "",
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      loading: false,
      salaryLoading: false,
      headers: [
        {
          text: this.$i18n.t("Employee Code"),
          align: "start",
          sortable: false,
          value: "code",
        },
        { text: this.$i18n.t("Name"), value: "name", sortable: false },
        { text: this.$i18n.t("Job Title"), value: "jobName", sortable: false },
        {
          text: this.$i18n.t("Planned Hrs"),
          value: "planned_hrs",
          sortable: false,
        },
        {
          text: this.$i18n.t("Actual Hrs"),
          value: "actual_hrs",
          sortable: false,
        },
        { text: this.$i18n.t("Hr/Rate"), value: "hr_rate", sortable: false },
        {
          text: this.$i18n.t("Basic Salary"),
          value: "basic_salary",
          sortable: false,
        },
        {
          text: this.$i18n.t("Allowance"),
          value: "allowances",
          sortable: false,
        },
        {
          text: this.$i18n.t("Deductions"),
          value: "deduction",
          sortable: false,
        },
        { text: this.$i18n.t("Net"), value: "net_salary", sortable: false },
        { text: this.$i18n.t("Actions"), value: "actions" },
      ],
      items: [],

      page: 1,
      length: 6,
      circle: true,
      totalVisible: "",
      totalItems: "",
      search: "",
      jobs: [],
      filter: {
        jobs: [],
        job: "",
        selectedYear: "",
        selectedMonth: "",
      },

      years: [],
      months: [],
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.getAllData(this.$route.query.page);
        }
      },
    },
    page: function () {
      this.$router.push(
        {
          path: "/allEmployees/salaries?page=" + this.page,
        },
        () => {}
      );
    },

    search: {
      handler() {
        this.page = 1;
        this.getAllData();
      },
      deep: true,
    },
    "filter.selectedMonth": {
      handler() {
        this.page = 1;
        this.getAllData();
      },
      deep: true,
    },
    "filter.selectedYear": {
      handler() {
        this.page = 1;
        this.getAllData();
      },
      deep: true,
    },
  },
  methods: {
    PrintPayslip(id) {
      axios
        .get(this.getApiUrl + "/salaries/printPayslipPdf/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            let printLink = response.data.data;
            window.open(printLink, "_blank");
          }
        });
    },
    calculateSalary() {
      if (this.filter.selectedMonth == "" || this.filter.selectedYear == "") {
        alert("Choose Month And year First");
      } else {
        this.salaryLoading = true;
        axios
          .post(
            this.getApiUrl + "/salaries/caculateSalary",
            {
              month: this.filter.selectedMonth,
              year: this.filter.selectedYear,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              this.snack = true;
              this.snackColor = "green";
              if (this.currentAppLocale == "ar") {
                this.snackText = "تم حساب المرتبات بنجاح";
              } else {
                this.snackText = "Salaries Calculated successfully";
              }
              this.page = 1;
              this.getAllData();
            } else {
              this.snack = true;
              this.snackColor = "red";
              if (this.currentAppLocale == "ar") {
                this.snackText = "خطأ في حساب المرتبات";
              } else {
                this.snackText = "Error Calculating Salary";
              }
            }
            this.salaryLoading = false;
          });
      }
    },
    getJobs() {
      axios
        .get(this.getApiUrl + "/employee/allJobs", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.jobs = response.data.data;
          // console.log(response);
        });
    },
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
        $(".input_search").parent("label").removeClass("active");
      }
    },
    getAllData(page = null) {
      // this.loading = true;
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl +
            "/salaries?search=" +
            this.search +
            "&page=" +
            this.page +
            "&month_id=" +
            this.filter.selectedMonth +
            "&year=" +
            this.filter.selectedYear,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
    getYears() {
      axios
        .get(this.getApiUrl + "/hrConfigration/getYears", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.years = response.data.data;
          // this.filter.selectedYear = this.years[0].year;
          var d = new Date();
          this.filter.selectedYear = String(d.getFullYear());
        });
    },

    getMonths() {
      axios
        .get(this.getApiUrl + "/dashboard/student/GetMonthsForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.months = response.data.data;
          // this.filter.selectedMonth = this.months[0].id;
          var d = new Date();
          this.filter.selectedMonth = d.getMonth() + 1;
        });
    },
    PrintSalaries() {
      axios
        .post(
          this.getApiUrl + "/salaries/printPdfSalary",
          {
            month_id: this.filter.selectedMonth,
            year: this.filter.selectedYear,
            job: this.filter.job,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            let printLink = response.data.data;
            window.open(printLink, "_blank");
          }
        });
    },
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page);
    }
    this.getAllData(this.page);
    this.getJobs();
    this.getYears();
    this.getMonths();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
</style>
